export const CollapsiblePosts = {
  MESSAGE_CARD_COLLAPSE_CLASS: "MessageCard__collapsed",
  JS_COLLAPSIBLE_SELECTOR: ".js-messageCard-collapsible",
  JS_POST_CONTENT_SELECTOR: ".js-originalPostContainer",
  JS_COLLAPSE_TOGGLE_SELECTOR: ".js-messageCard-collapseToggle",
  init(parentNode?: Element) {
    const messageCardElems = this.getCollapsiblePostElements(
      parentNode || document
    );

    messageCardElems.forEach((postElem) => {
      this.bindCollapseToggleEventToPost(postElem);
    });
  },
  getCollapsiblePostElements(node: Element | Document) {
    return node.querySelectorAll(this.JS_COLLAPSIBLE_SELECTOR);
  },
  bindCollapseToggleEventToPost(postElem: Element) {
    const toggleButton = postElem.querySelectorAll(
      this.JS_COLLAPSE_TOGGLE_SELECTOR
    );

    toggleButton.forEach((buttonElem) => {
      buttonElem.addEventListener("click", () => {
        this.toggleReplyClassesAndText(
          postElem,
          buttonElem.classList.contains("MessageCard__collapse-less")
        );
      });
    });
  },
  toggleReplyClassesAndText(postElem: Element, canScroll: boolean) {
    const postContentElem = postElem.querySelector(
      this.JS_POST_CONTENT_SELECTOR
    );

    postContentElem?.classList.toggle(this.MESSAGE_CARD_COLLAPSE_CLASS);

    if (canScroll) {
      this.scrollPostIntoView(postElem);
    }
  },
  scrollPostIntoView(postContainer: Element) {
    const seeMoreElement = postContainer.querySelector(
      ".MessageCard__collapse-more"
    );
    const postContainerOffset =
      seeMoreElement?.getBoundingClientRect().top || 0;
    const headerContainer = document.getElementById("cali-header");
    const headerContainerHeight =
      headerContainer?.getBoundingClientRect().height || 0;

    if (postContainerOffset - headerContainerHeight < 0) {
      window.scroll(
        0,
        postContainerOffset + window.scrollY - headerContainerHeight
      );
    }
  },
};

CollapsiblePosts.init();
